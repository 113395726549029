import React, {useCallback, useEffect, useState, CSSProperties} from 'react';
import Container from "@mui/material/Container";
import NavBar from "./Navbar";
import Grid from "@mui/material/Grid";
import TopCollectionDisplay from "./TopCollectionDisplay";
import {Collection} from "../types/Collection";
import {useOrdConnect, useSignMessage} from "@ordzaar/ord-connect";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import ExplorerAssetCard from "./ExplorerAssetCard";
import {styled} from "@mui/material";
import {ButtonProps} from "@mui/material/Button";

type MagicEdenInscription =  {
    "symbol": string,
    "name": string,
    "imageURI": string,
    "inscriptionIcon": string,
    "collectionInscriptionId": null,
    "description": string,
    "twitterLink": string,
    "discordLink": string,
    "websiteLink": string,
    "totalVolume": string,
    "sales": string,
    "salesInMempool": string,
    "floorPrice": string,
    "owners": string,
    "supply": number,
    "volume": string
}

const CollectionBanner = styled(Container)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText('#000124'),
    backgroundColor: '#000000',
    width: '100%',
    height: 'auto',
    fontSize: '1rem',
    border: '1px solid #2c2c2c',
    borderRadius: '.75rem',
    textAlign: 'left',
    fontFamily: 'FWD',
    cursor: 'pointer',
    boxShadow: '0 0 3px rgba(0, 0, 0, 0.0)',
    transition: 'border 0.3s',
    padding: '24px',

    '&:hover': {
        border: '1px solid #00BFFF',
        boxShadow: '0 0 4px #00BFFF',
    },
}));

const Hub = () => {
    ReactGA.send({
        hitType: "pageView",
        page: '/hub',
        title: "Hub"
    })

    const [topCollections, setTopCollections] = useState<MagicEdenInscription[]>([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const { address, wallet } = useOrdConnect();
    const dustThreshold = 546; // Typical dust threshold for P2WPKH outputs

    const [collectionMap, setCollectionMapState] = useState<Map<string,Collection>>(new Map());
    const setCollectionMap = (map: Map<string, Collection>) => {
        setCollectionMapState(map);
    }

    const scrollDown = () => {
        window.scrollBy({ top: window.innerHeight, left: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        fetch(`https://ordia.xyz:444/api/v2/ord/btc/popular_collections?window=1d`,
            {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Basic ${process.env.REACT_APP_MAGIC_EDEN_API}`,
                    'Content-Type': 'application/json'
                }),
            }
        )
            .then((response) => {
                if(!response.ok) {
                    console.log("handle error")
                }
                return response.json()
            })
            .then((results: MagicEdenInscription[]) => {
                setTopCollections(results)
                setIsLoaded(true)
            })
            .catch((err) => {
                console.log("error: ", err);
            })
    }, [address]);

    let navigate = useNavigate();
    const routeChange = (id: string) =>{
        let path = `/collection/${id}`;
        navigate(path);
    }

    const routeChangeOut = (link: string) =>{
        window.open(link, '_blank'); // Opens in a new tab

    }

    const textStyle: CSSProperties = {
        fontFamily: 'FWD',
        width: '100%',
        fontSize: '24px',
        textAlign: 'left',
        justifyContent: 'left',
        padding: '12px 12px'
    }

    const textStyleBlue: CSSProperties = {
        fontFamily: 'FWD',
        width: '100%',
        fontSize: '18px',
        textAlign: 'left',
        justifyContent: 'left',
        padding: '12px 12px',
        color: '#00BFFF'
    }

    return (
        <div>
            <Container style={{textAlign: 'center', maxWidth: '1152px', overflow: 'scroll', marginTop: '10vh'}}>
                <NavBar isHub={true}/>
                <Grid container spacing={4}>

                    <Grid item xs={12} md={12}>
                        <CollectionBanner>
                            <div style={textStyle}>
                                Welcome to Ordia
                            </div>

                            <div style={textStyleBlue}>
                                Join our mission to bring Ordinal interactivity to bitcoin.
                            </div>

                            <div style={textStyleBlue}>
                                New Modules coming soon...
                            </div>
                        </CollectionBanner>


                    </Grid>

                    <Grid item xs={3} md={3} className="img_wrap">
                        <ExplorerAssetCard
                            title="Bitcoin Spacemen"
                            imageSrc="./4.png"
                            magicEdenCollectionName="bitcoin_spacemen"
                            onClick={() => routeChange("bitcoin_spacemen")}
                            onClickOut={() => routeChangeOut("https://ordia.xyz")}
                        />
                    </Grid>

                    <Grid item xs={3} md={3} className="img_wrap">
                        <ExplorerAssetCard
                            title="Node Monkes"
                            imageSrc="./1.png"
                            magicEdenCollectionName="nodemomnke"
                            onClick={() => routeChange("nodemonkes")}
                            onClickOut={() => routeChangeOut("https://nodemonkes.com/monkedex")}
                        />
                    </Grid>

                    <Grid item xs={3} md={3} className="img_wrap">
                        <ExplorerAssetCard
                            title="Frucks"
                            imageSrc="./3.png"
                            magicEdenCollectionName="frucks"
                            onClick={() => routeChange("frucks")}
                            onClickOut={() => routeChangeOut("https://x.com/frucksbtc")}
                        />
                    </Grid>

                    <Grid item xs={3} md={3} className="img_wrap">
                        <ExplorerAssetCard
                            title="Bitcoin Frogs"
                            imageSrc="./2.png"
                            magicEdenCollectionName="bitcoin-frogs"
                            onClick={() => routeChange("bitcoin-frogs")}
                            onClickOut={() => routeChangeOut("https://bitcoinfrogs.com/")}
                        />
                    </Grid>



                    <Grid item xs={12} md={12}>
                        <CollectionBanner >
                            <Grid container>
                                <Grid item xs={12} md={12}>
                                    <div style={{
                                        fontFamily: 'FWD',
                                        width: '100%',
                                        fontSize: '24px',
                                        textAlign: 'left',
                                        justifyContent: 'left',
                                        padding: '24px 24px'
                                    }}>
                                        Top Realms
                                    </div>
                                </Grid>

                                <div style={{width: '100%',  textAlign: 'center'}}>
                                    {
                                        !isLoaded && (
                                            <CircularProgress />
                                        )
                                    }
                                </div>
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={2} key={0} style={{fontFamily: 'FWD'}}>
                                        <Grid item xs={1} sm={1} md={1}>
                                        </Grid>
                                        <Grid item xs={10} sm={10} md={10}>
                                            <Grid container style={{ display: 'flex', alignItems: 'left', justifyContent: 'left'}}>
                                                <Grid item xs={3} sm={2} md={2} style={{padding: '4px', paddingTop: '8px'}}>
                                                </Grid>
                                                <Grid item xs={6} sm={4} md={4} style={{padding: '4px', paddingTop: '8px'}}>
                                                    <div style={{fontSize: '.75em'}}>
                                                        Collection Name
                                                    </div>
                                                </Grid>
                                                <Grid item sm={3} md={3} style={{padding: '4px', paddingTop: '8px'}} sx={{ display: { xs: 'none', sm: 'block' } }}>
                                                    <div style={{fontSize: '.75em', height: '50%'}}>
                                                        Floor
                                                    </div>
                                                </Grid>
                                                <Grid item sm={3} md={3} style={{padding: '4px', paddingTop: '8px'}} sx={{ display: { xs: 'none', sm: 'block' } }}>
                                                    <div style={{fontSize: '.75em', height: '50%'}}>
                                                        Volume
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {
                                        topCollections && topCollections.length > 0 && topCollections.slice(0, 5).map((item, index) => (
                                            <TopCollectionDisplay key={index} index={index} symbol={item.symbol}
                                                                  imageURI={item.imageURI} name={item.name}
                                                                  floorPrice={item.floorPrice} volume={item.volume}/>
                                        ))
                                    }
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={2} key={0} style={{fontFamily: 'FWD'}} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                        <Grid item xs={10} sm={10} md={10}>
                                            <Grid container style={{ display: 'flex', alignItems: 'left', justifyContent: 'left'}}>
                                                <Grid item xs={2} md={2} style={{padding: '4px', paddingTop: '8px'}}>
                                                </Grid>
                                                <Grid item xs={4} md={4} style={{padding: '4px', paddingTop: '8px'}}>
                                                    <div style={{fontSize: '.75em'}}>
                                                        Collection Name
                                                    </div>
                                                </Grid>
                                                <Grid item xs={3} md={3} style={{padding: '4px', paddingTop: '8px'}}>
                                                    <div style={{fontSize: '.75em', height: '50%'}}>
                                                        Floor
                                                    </div>
                                                </Grid>
                                                <Grid item xs={3} md={3} style={{padding: '4px', paddingTop: '8px'}}>
                                                    <div style={{fontSize: '.75em', height: '50%'}}>
                                                        Volume
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {
                                        topCollections && topCollections.length > 0 && topCollections.slice(5, 10).map((item, index) => (
                                            <TopCollectionDisplay key={index} index={index + 5} symbol={item.symbol}
                                                                  imageURI={item.imageURI} name={item.name}
                                                                  floorPrice={item.floorPrice} volume={item.volume}/>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        </CollectionBanner>

                    </Grid>
                </Grid>


                <div style={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '10px',
                }}>
                    <button onClick={scrollDown}
                            style={{fontSize: '24px', cursor: 'pointer', background: 'none', border: 'none'}}>
                        ️<KeyboardArrowDownIcon style={{color: 'white'}}/>
                    </button>
                </div>
            </Container>
        </div>
    );
}

export default Hub;
