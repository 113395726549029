import React, {useCallback, useEffect, useState} from "react";
import {useOrdConnect, useSignMessage} from "@ordzaar/ord-connect";

import Grid from "@mui/material/Grid";
import ColorButton from "./styled/ColoredButton";
import Badge from '@mui/material/Badge';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import axios from 'axios';
import ColoredButton from "./styled/ColoredButton";
import Alert from '@mui/material/Alert';

type ClaimsProps = {
    inscription_id: string | null | undefined;
    collection_id: string | null | undefined;
}

type Claim = {
    id: number;
    collection_id: string;
    claim_id: number;
    public_id: string;
    user_address: string;
    signed_msg: string;
    inscription_id: string;
}

const Claims = ({inscription_id, collection_id}: ClaimsProps) => {
    const { address, wallet, publicKey } = useOrdConnect();
    const { signMsg, error: signMessageError } = useSignMessage();
    const [signed, setSigned] = useState<string|null>();

    const [open, setOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [status, setStatus] = useState('');

    const [claims, setClaims] = useState<Map<string, Claim>>();

    // Errors
    const [showError, setShowError] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string>();

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setDialogContent(`You have 1 claim available`);
        setOpen(true);
    };

    const handleSignMessage = useCallback(async () => {
        if (!address.ordinals) {
            throw new Error("No payment address");
        }

        const signed = await signMsg(
            address.ordinals,
            address.ordinals+":"+inscription_id,
        );

        setSigned(signed);
        handleClaim(signed).then((res) => {
            setOpen(false);
        }).catch((err) => {
            setStatus("Failed to sign vote: ");
            setShowError(true);
            setErrorMsg('api network having issues, please try again later.')
        })
    }, [address.ordinals, signMsg]);

    const fetchClaims = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/claims/${collection_id}`, {});
            if(response && response.data) {
                const map: Map<string, Claim> = new Map(response.data.map((obj: Claim) => [obj.inscription_id, obj]));
                if(map) {
                    setClaims(map);
                }
            }
        } catch (err) {
            setShowError(true);
            setErrorMsg(`api network having issues, please try again later: ${err}`);
        }
    };

    const handleClaim = async (signedMsg: string | null) => {
        const postData = {
            user_address: address.ordinals,
            collection_id: collection_id,
            signed_msg: signedMsg,
            public_key: publicKey.ordinals,
            inscription_id: inscription_id,
        };

        axios.post(`${process.env.REACT_APP_SERVER_URL}/claim`, postData)
            .then(response => {
                fetchClaims();
            })
            .catch(error => {
                setShowError(true);
                setErrorMsg('api network having issues, please try again later.')
            });
    };

    useEffect(() => {
        fetchClaims()
            .catch((err) => {
                setShowError(true);
                setErrorMsg('api network having issues, please try again later.')
            });
    }, []);

    let isClaimAvailable = false
    let isClaimClaimed = false
    if(claims && address && address.ordinals && inscription_id && !claims?.has(inscription_id)) {
        isClaimAvailable = true;
    } else {
        isClaimAvailable = false;

        // if no claims made but open
        if(claims === undefined) {
            isClaimAvailable = false;
        }
    }

    return (
        <Grid item xs={12} md={12}>
            <Grid container>
                <Grid item xs={12} md={12} style={{padding: '1rem', textAlign: 'center'}}>
                    <Badge badgeContent={isClaimAvailable ? 1 : 0} color="success">
                        {
                            isClaimAvailable ? (
                                <ColorButton onClick={handleOpen}> Claim </ColorButton>
                            ) : (
                                <ColorButton onClick={() => {}}> Claimed </ColorButton>
                            )
                        }
                    </Badge>
                </Grid>
            </Grid>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" style={{background: '#414141'}}>
                    <div style={{width: "100%", textAlign: 'center', color: '#ffffff'}}>

                        Claims
                    </div></DialogTitle>
                <DialogContent style={{background: '#414141'}}>
                    <DialogContentText id="alert-dialog-description" style={{color: '#ffffff'}}>
                        {
                            isClaimAvailable ? (
                                dialogContent
                            ) : (
                                "no claims available"
                            )
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{background: '#414141'}}>
                    <ColoredButton onClick={handleClose} color="primary">
                        Cancel
                    </ColoredButton>
                    {
                        isClaimAvailable && (
                            <ColoredButton onClick={handleSignMessage} color="primary" autoFocus>
                                Claim
                            </ColoredButton>
                        )
                    }
                </DialogActions>
            </Dialog>
            {
                showError && (
                    <Alert severity="error" style={{fontFamily: 'FWD'}} onClose={() => setShowError(false)}>
                        Error: {errorMsg}
                    </Alert>
                )
            }
        </Grid>
    )
}

export default Claims;