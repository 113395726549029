import React from 'react';
import { Grid, Card, CardContent, Avatar, Typography, IconButton } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import ShowChartIcon from '@mui/icons-material/ShowChart';

// AssetCard Component
const ExplorerAssetCard: React.FC<{ title: string; imageSrc: string; magicEdenCollectionName: string; onClick: () => void; onClickOut: () => void }> = ({ title, imageSrc, onClick , onClickOut}) => {
    return (
        <Card
            sx={{
                maxWidth: 300,
                backgroundColor: '#000000',
                borderRadius: '20px',
                fontFamily: 'FWD',
                boxShadow: '0 8px 16px rgba(0,0,0,0.3)',
                padding: '24px',
                textAlign: 'center',
            }}
            className="hoverClass2"
            onClick={onClick}
        >
            <Avatar
                alt={title}
                src={imageSrc}
                sx={{
                    width: '50%',
                    height: '50%',
                    margin: '10% auto 6px auto',
                    backgroundColor: 'white',
                }}
                onClick={onClick}
            />
            <CardContent>
                <div style={{fontFamily: 'FWD', color: 'white', fontSize: '16px', paddingBottom: '12px'}}>
                    {title}
                </div>
                <Grid container justifyContent="center" spacing={3}>
                    <Grid item>
                        <IconButton color="primary" onClick={onClickOut}>
                            <LanguageIcon style={{ color: 'white' }} />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton color="primary" onClick={onClickOut}>
                            <ShowChartIcon style={{ color: 'white' }} />
                        </IconButton>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ExplorerAssetCard;
