import {styled} from "@mui/material";
import Button, {ButtonProps} from "@mui/material/Button";

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText('#000124'),
    backgroundColor: '#1E2342',
    borderRadius: '48px',
    paddingTop: '8px',
    paddingLeft: '12px',
    paddingRight: '12px',
    textTransform: 'none',
    fontWeight: '800',
    cursor: 'pointer',
    boxShadow: '0 0 7px rgba(0, 0, 0, 0.0)',
    transition: 'border 0.3s',
    border: '1px solid #3b3b3b',

    fontFamily: 'FWD',

    '&:hover': {
        border: '2px solid #00BFFF',
        boxShadow: '0 0 4px #00BFFF',
    },
}));

export default ColorButton;