import React, {useEffect, useState} from 'react';
import GLTFViewer from "./GLTFViewer";
import {Container} from "@mui/material";
import { Link } from 'react-router-dom';

type InscriptionContentProps = {
    inscriptionId: string | undefined;
    width: string;
    height: string;
    type: string | undefined;
    imageURL: string | undefined;
}

const InscriptionContent = ({ inscriptionId, height, width, type, imageURL }: InscriptionContentProps) => {
    const [imageUrl, setImageUrl] = useState('');
    const [blob, setBlob] = useState<Blob>()
    const [hover, setHover] = useState(false);

    // TODO: Complete hover action
    const onHover = (e: any) =>{
        e.preventDefault();
        setHover(true);
    }

    // TODO: Complete hover action
    const onHoverOver = (e: any) => {
        e.preventDefault();
        setHover(false);
    }

    const fetchImageStream = async () => {
        // TODO: Paramterize IP Address of Ord API
        try {
            const response = await fetch(imageUrl);
            if (!response.body) {
                return
            }
            const reader = response.body.getReader();
            let chunks = [];

            const stream = new ReadableStream({
                async start(controller) {
                    while (true) {
                        const { done, value } = await reader.read();
                        if (done) {
                            break;
                        }
                        chunks.push(value);
                        controller.enqueue(value);
                    }
                    controller.close();
                    reader.releaseLock();
                }
            });

            // Wait until the stream is fully read
            new Response(stream).blob().then(blob => {
                setBlob(blob); // set binary blob for gltf
                const objectURL = URL.createObjectURL(blob);
                setImageUrl(objectURL);
            });

        } catch (error) {
            console.log(error);
        }
    };

    const fetchImageInscription = async () => {
        const url = `https://ordia.xyz:444/api/v2/ord/btc/tokens?tokenIds=${inscriptionId}`
        fetch(url,
            {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Basic ${process.env.REACT_APP_MAGIC_EDEN_API}`,
                    'Content-Type': 'application/json'
                }),
            })
            .then(resp => resp.json())
            .then((inscription) => {
                setImageUrl(inscription.tokens[0].contentPreviewURI);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        // either fetch image stream, or fetch content url
        if(type === "image/png" || type==="image/jpeg" || type === "image/gif") {
            // console.log("image type is png")
            // console.log(inscriptionId);
           //  console.log(fetchImageInscription());
        } else if (type === "model/gltf") {
        } else {
        }

        fetchImageStream();
       //  fetchImageInscription();

        // TODO: Revoke if model/gltf?
        // Cleanup function to revoke the created object URL
        // return () => imageUrl && URL.revokeObjectURL(imageUrl);
    }, [inscriptionId, type]);

    return (
        <Container disableGutters={true}>
            {/*(type === "image/webp" || type === "model/gltf+json") && blob
                ? <GLTFViewer blob={blob}/>
                : <img
                    alt={""}
                    onMouseEnter={(e) => onHover(e)}
                    onMouseLeave={(e) => onHoverOver(e)}
                    className="inscription-content" width={"100%"} height={"auto"} src={imageUrl}
                />
            */}
            {/* hover && <p className={"hover-text"} style={{position: 'absolute'}}>View</p>*/}
            <div style={{width: '100%', height: height, maxHeight: '540px'}}>
                <iframe style={{border: 'none !important', borderRadius: '12px'}} scrolling="no"
                        width="100%"
                        height="100%"
                        sandbox="allow-scripts"
                        loading="lazy"
                        src={imageURL}></iframe>
            </div>

        </Container>
    );
}

export default InscriptionContent;