import React, { useState, useEffect } from 'react';
import InscriptionContent from "./InscriptionContent";
import {ImageList, ImageListItem, useMediaQuery, useTheme} from "@mui/material";
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { Link } from 'react-router-dom';
import {Collection} from "../types/Collection";
import PreviewIcon from '@mui/icons-material/Preview';
import { useDataContext } from './DataContext';

type ContentResponse = {
    "id": string,
    "number": number,
    "address": string,
    "tx_id": string,
    "value": string,
    "mime_type": string,
    "content_type": string,
    "content_length": number,
    "curse_type": null,
    "recursive": boolean
}

type ObjProps = {
    ordinalAddress: string | null;
    isMyHubFilter: boolean;
    isMyOrdinalFilter: boolean;

    setCollectionMap: (map: Map<string, Collection>) => void;
}

const ProfileDisplay = ({ordinalAddress, isMyHubFilter, isMyOrdinalFilter, setCollectionMap}: ObjProps) => {
    const [inscriptions, setInscriptions] = useState<ContentResponse[]>([]);
    // TODO: Set Loading vars
    const [isLoading, setIsLoading] = useState(false);
    const theme = useTheme();
    const { data, setData } = useDataContext();

    useEffect(() => {
        fetch(`https://api.hiro.so/ordinals/v1/inscriptions?limit=50&address=${ordinalAddress}`)
            .then((response) => {
                if(!response.ok) {
                    console.log("handle error")
                }
                return response.json()
            })
            .then((data) => {
                const collectionsMap: Map<string, Collection> = new Map(data.results.map((obj: Collection) => [obj.id, obj]));
                setCollectionMap(collectionsMap);

                return data.results
            })
            .then((results: ContentResponse[]) => {
                const inscriptionList = results.filter(item => item.content_type.startsWith('image/'))
                setInscriptions(inscriptionList);
            })
            .catch((err) => {
                console.log("error: ", err);
            })
    }, [ordinalAddress]);

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <ImageList cols={matchDownMd ? 3 : 6 } sx={{px: 0}}>
            {
                inscriptions && inscriptions.length > 0 ? (
                    inscriptions.map((inscription: ContentResponse, index) => (
                        <ImageListItem sx={{ textAlign: 'left'}} key={index} style={{ borderRadius: '.75rem', overflow: 'hidden', background: '#05000e'}}
                                       className="hoverClass">
                            <InscriptionContent key={index}
                                                inscriptionId={inscription.id}
                                                width={'16vw'}
                                                height={'16vw'}
                                                type={inscription.content_type}
                                                imageURL={data.get(inscription.id)?.contentPreviewURI}
                            />
                            <ImageListItemBar
                                sx={{p: 1}}
                                title={<span style={{fontSize: '12px'}}>#{inscription.number}</span>}
                                subtitle={
                                    <div>
                                        <span style={{fontSize: '14px', fontWeight: 'bold'}}>{data && data.get(inscription.id)?.collection && data.get(inscription.id)?.collection.name}</span>

                                    </div>
                                }
                                actionIcon={
                                    <div style={{padding: '24px 0px 8px 8px'}}>
                                        <Link to={`/inscription/${inscription.id}`}>
                                            <PreviewIcon style={{color: '#87CEEB'}}/>
                                        </Link>
                                    </div>
                                }
                                position="below"
                            />
                        </ImageListItem>
                    ))
                ) : (
                    <div style={{fontFamily: 'FWD'}}>No Ordinals</div>
                )
            }
        </ImageList>
    );
}

export default ProfileDisplay;
