import React from 'react';
import Grid from "@mui/material/Grid";
import { Link } from 'react-router-dom';

type TopCollectionProps = {
    index: number;
    imageURI: string;
    name: string;
    floorPrice: string;
    volume: string;
    symbol: string;
}

const TopCollectionDisplay = (props: TopCollectionProps) => {
    return (
        <Grid container spacing={2} key={props.index} style={{paddingTop: '8px', fontFamily: 'FWD'}}>
            <Grid item xs={1} sm={1} md={1}>
                <div style={{paddingLeft: '4px', paddingTop: '24px', fontFamily: 'FWD'}}>{props.index+1}</div>
            </Grid>
            <Grid item xs={10} sm={10} md={10}>
                <Grid container style={{background: '#121212', borderRadius: '4px', display: 'flex', alignItems: 'center', justifyContent: 'center'}} className="hoverClass">
                    <Grid item xs={4} sm={2} md={2} style={{padding: '4px', paddingTop: '8px'}}>
                        <Link
                            to={`/collection/${props.symbol}`}>
                            <img src={props.imageURI} width={"48px"} height={"48px"} style={{borderRadius: '12px'}}/>
                        </Link>
                    </Grid>
                    <Grid item xs={8} sm={4} md={4} style={{ maxHeight: '48px', overflow: 'hidden'}}>
                        <Grid container style={{textAlign: 'left', paddingLeft: '8px'}}>
                            <div style={{fontSize: '1em'}}>
                                {props.name}
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} md={3} style={{fontSize: '1em'}} sx={{ display: { xs: 'none', sm: 'block' }}}>
                        <div style={{fontSize: '1em'}}>
                            {(Number(props.floorPrice)/Math.pow(10,8)).toFixed(3)
                            }<span style={{fontSize:'8px'}}>btc</span>
                        </div>
                    </Grid>
                    <Grid item xs={3} md={3} sx={{ display: { xs: 'none', sm: 'block' }}}>
                        <div style={{fontSize: '1em'}}>
                            {(Number(props.volume) / Math.pow(10, 8)).toFixed(3)}
                            <span style={{fontSize: '8px'}}>btc</span>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TopCollectionDisplay;