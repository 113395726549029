import React from 'react';
import {useState} from "react";
import {useOrdConnect} from "@ordzaar/ord-connect";
import ProfileDisplay from "./ProfileDisplay";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ColorButton from "./styled/ColoredButton";
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import Container from "@mui/material/Container";

import {Collection} from "../types/Collection";
import NavBar from "./Navbar";

interface ordHubProps{
    isHub: boolean;

    setCollectionMap: (map: Map<string, Collection>) => void;
}

const Profile = (props: ordHubProps) => {
    const { address, wallet } = useOrdConnect();

    const [isMyOrdinalFilter, setIsMyOrdinalFilter] = useState(true);
    const [isMyHubFilter, setIsMyHubFilter] = useState(true);

    return (
        <div style={{paddingTop: '24px'}}>
            <NavBar isHub={false}/>

            <Container disableGutters={true} maxWidth={false} style={{borderBottom: '1px solid #000124', paddingTop: '12px', paddingLeft: '12px'}}>

                <div style={{textAlign: 'left', display: 'flex', justifyContent: 'center', paddingTop: '24px'}}>
                    <AccountCircleIcon sx={{fontSize: 100, color: "gray"}}/>
                </div>

                <div style={{textAlign: 'left', display: 'flex', justifyContent: 'center', paddingTop: '24px'}}>
                    {address?.ordinals ? (
                        <ColorButton style={{padding: '4px 8px', fontFamily: 'arial'}}><CurrencyBitcoinIcon/> &nbsp; {address.ordinals ?? ""}</ColorButton>
                    ) : null}
                </div>
                <br/>
            </Container>

            <ProfileDisplay setCollectionMap={props.setCollectionMap} isMyHubFilter={isMyHubFilter} isMyOrdinalFilter={isMyOrdinalFilter} ordinalAddress={address.ordinals}/>
        </div>
    );
}

export default Profile;
