import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import axios from 'axios';
import { TextField } from '@mui/material';
import ColoredButton from "./styled/ColoredButton";

type ClaimsProps = {
    collection_id: string | null | undefined;
}

type Claim = {
    id: number;
    collection_id: string;
    claim_id: number;
    public_id: string;
    user_address: string;
    signed_msg: string;
    inscription_id: string;
}

const Claims = ({ collection_id }: ClaimsProps) => {
    const [claims, setClaims] = useState<Map<string, Claim>>(new Map());
    const [allInscriptions, setAllInscriptions] = useState<Set<string>>(new Set());
    const [search, setSearch] = useState<string>('');
    const [status, setStatus] = useState<'available' | 'claimed' | 'not_found' | null>(null);

    const fetchClaims = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/claims/${collection_id}`);
            if (response && response.data) {
                const map: Map<string, Claim> = new Map(response.data.map((obj: Claim) => [obj.inscription_id, obj]));
                setClaims(map);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const fetchCSV = async () => {
        try {
            const response = await fetch('/inscriptions_spacemen.csv');
            const reader = response.body?.getReader();
            const decoder = new TextDecoder('utf-8');
            const result = await reader?.read();
            const csv = decoder.decode(result?.value);
            parseCSV(csv);
        } catch (err) {
            console.error(err);
        }
    };

    const parseCSV = (csv: string) => {
        const lines = csv.split('\n');
        const inscriptionSet = new Set<string>();

        for (const line of lines) {
            const [inscription_id] = line.split(',');
            if (inscription_id) {
                inscriptionSet.add(inscription_id.trim());
            }
        }

        console.log(inscriptionSet);

        setAllInscriptions(inscriptionSet);
    };

    useEffect(() => {
        fetchClaims().catch((err) => {
            console.error(err);
        });
        fetchCSV().catch((err) => {
            console.error(err);
        });
    }, [collection_id]);

    const isFound = (inscription_id: string) => {
        setSearch(inscription_id);
        setStatus(null); // Reset the state when typing
    }

    const staticCheck = () => {
        if (allInscriptions.has(search)) {
            if (claims.has(search)) {
                setStatus('claimed');
            } else {
                setStatus('available');
            }
        } else {
            setStatus('not_found');
        }
    };

    return (
        <Grid item xs={12} md={12}>
            <div style={{ padding: '1rem', textAlign: 'center' }}>
                Check Claims
            </div>

            <TextField
                style={{ background: "#141414", color: 'red' }}
                sx={{
                    '& .MuiInputBase-input': {
                        color: '#00BFFF', // Change input text color
                        fontFamily: 'FWD',
                    },
                    '& .MuiInputLabel-root': {
                        color: '#00BFFF', // Change label text color
                        fontFamily: 'FWD',
                    }
                }}
                label="[inscription id]"
                variant="outlined"
                fullWidth
                margin="normal"
                value={search}
                onChange={(e) => isFound(e.target.value)}
            />
            <div style={{paddingTop: '12px'}}>
                <ColoredButton onClick={staticCheck}> CHECK CLAIMED </ColoredButton>
            </div>

            {
                status === 'claimed' && (
                    <ColoredButton style={{ background: 'red' }}> ALREADY CLAIMED </ColoredButton>
                )
            }
            {
                status === 'available' && (
                    <ColoredButton style={{ background: 'green' }}> AVAILABLE </ColoredButton>
                )
            }
            {
                status === 'not_found' && (
                    <ColoredButton style={{ background: 'gray' }}> NOT FOUND </ColoredButton>
                )
            }
        </Grid>
    )
}

export default Claims;
