import React, {useEffect, useState, useCallback} from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {useOrdConnect} from "@ordzaar/ord-connect";
import { useNavigate } from "react-router-dom";

import InscriptionContent from "./InscriptionContent";
import { Link } from 'react-router-dom';
import {styled} from "@mui/material";
import {ButtonProps} from "@mui/material/Button";
import Container from "@mui/material/Container";
import PreviewIcon from '@mui/icons-material/Preview';
import NavBar from "./Navbar";
import Grid from "@mui/material/Grid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import CircularProgress from '@mui/material/CircularProgress';
import Claims from "./Claims";
import {MagicEdenTokens, MagicEdenApiTokenResp, HiroContentResp, MagicEdenCollectionResponse} from "../types/MagicEden"
import CreateProposal from "./CreateProposal";
import ColoredButton from "./styled/ColoredButton";
import Alert from '@mui/material/Alert';
import CheckClaims from "./CheckClaims";

const CollectionBanner = styled(Container)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText('#000124'),
    backgroundColor: '#000000',
    width: '100%',
    height: 'auto',
    fontSize: '1rem',
    border: '2px solid #2c2c2c',
    borderRadius: '.75rem',
    textAlign: 'left',
    fontFamily: 'FWD',
    cursor: 'pointer',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.0)',
    transition: 'border 0.3s',
    paddingBottom: '24px',

    '&:hover': {
        border: '1px solid #00BFFF',
        boxShadow: '0 0 8px #00BFFF',
    },
}));

const CollectionStatCard = styled(Container)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText('#000124'),
    width: '100%',
    height: 'auto',
    fontSize: '1rem',
    border: '1px solid #3b3b3b',
    padding: '1rem',
    borderRadius: '1.5rem',
    textAlign: 'center',
    background: '#141414',
    fontFamily: 'FWD',
    cursor: 'pointer',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.0)',
    transition: 'border 0.3s',

    '&:hover': {
        border: '1px solid #00BFFF',
        boxShadow: '0 0 8px #00BFFF',
    },
}));

type ProposalType = {
    id: number;
    title: string;
    description: string;
    index: number;
    category: string;
    inscription_address: string;
    created_at: string;
    last_updated: string;
}


const OrdCollection = () => {
    const [inscriptions, setInscriptions] = useState<MagicEdenTokens[]>([]);
    const [collection, setCollection] = useState<MagicEdenCollectionResponse>();
    const [collectionDetails, setCollectionDetails] = useState<MagicEdenCollectionResponse>();
    // TODO: Set Loading vars
    const [isLoading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [proposals, setProposals] = useState<ProposalType[]>([]);

    // Inscription ID Path Param
    const { id } = useParams()

    // Get Profile Info
    const { address, wallet } = useOrdConnect();
    const [profileInscriptions, setProfileInscriptions] = useState<string[]>([]);

    // Errors
    const [showError, setShowError] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string>();

    const [showProposalForm, setShowProposalForm] = useState(false);
    const hideSetProposal = () => {
        setShowProposalForm(!showProposalForm)
    }

    const fetchProposals = useCallback(async () => {
        if (!address) {
            setProposals([]);
            return;
        }
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/proposals/${id}`);
            setProposals(response.data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    }, [address, id]);

    useEffect(() => {
        fetchProposals();
    }, [fetchProposals]);

    useEffect(() => {
        fetch(`https://ordia.xyz:444/api/v2/ord/btc/collections/${id}`,
            {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Basic ${process.env.REACT_APP_MAGIC_EDEN_API}`,
                    'Content-Type': 'application/json'
                }),
            })
            .then((res) => res.json())
            .then((response: MagicEdenCollectionResponse) => {
                setCollection(response);
                return fetch(`https://ordia.xyz:444/api/v2/ord/btc/stat?collectionSymbol=${id}`,
            {
                method: 'GET',
                headers: new Headers({
                    'Authorization': `Basic ${process.env.REACT_APP_MAGIC_EDEN_API}`,
                    'Content-Type': 'application/json'
                })
            })
                .then((response) => response.json())
                .then((res: MagicEdenCollectionResponse) => {
                    setCollectionDetails(res)
                    setIsLoading(false)
                }).catch((err) => {
                    setShowError(true);
                    setErrorMsg('api network having issues, please try again later.')
                })
            }).catch((err) => {            setShowError(true);
            setErrorMsg('api network having issues, please try again later.')

        })}, [])

    useEffect(() => {
        if(address && address.ordinals) {
            fetch(`https://api.hiro.so/ordinals/v1/inscriptions?address=${address.ordinals}`)
                .then((response) => response.json())
                .then((data) => data.results)
                .then((results: HiroContentResp[]) => {
                    const inscriptionList = results.filter(item => item.content_type.startsWith('image/')).map((obj) => obj.id)
                    setProfileInscriptions(inscriptionList);

                    return fetch(`https://ordia.xyz:444/api/v2/ord/btc/tokens?tokenIds=${inscriptionList.join(",")}`,{
                        method: 'GET',
                        headers: new Headers({
                            'Authorization': `Basic ${process.env.REACT_APP_MAGIC_EDEN_API}`,
                            'Content-Type': 'application/json'
                        }),
                    })
                })
                .then((response) => response.json())
                .then((res: MagicEdenApiTokenResp) => {
                    const collectionName = id ? id : "";

                    let filteredInscriptions = res.tokens.filter(item => {
                        if(item && item.collection) {
                            return item.collection.symbol === collectionName
                        } else {
                            return false;
                        }
                    });

                    setInscriptions(filteredInscriptions)
                    return
                })
                .catch((err) => {
                    // TODO: fix api error with no inscriptions
                    // setShowError(true);
                    // setErrorMsg('api network having issues, please try again later.')
                })
        }
    }, []);

    let navigate = useNavigate();
    const routeChange = (id: string) =>{
        let path = `/inscription/${id}`;
        navigate(path);
    }

    let isClaimable = false
    if(address && address.ordinals && collection && collection.symbol === "bitcoin_spacemen"){
        isClaimable = true
    }

    let isOwner = false
    if(collection && collection.symbol === "runestonewizards"){
        isOwner = true
    }

    return (
        <div style={{paddingTop: '48px'}}>
            <NavBar isHub={false}/>

            <Container disableGutters={true} maxWidth={false} style={{borderBottom: '1px solid #000124', paddingLeft: '12px', maxWidth: '1152px'}}>
                <Grid container spacing={4}>
                    <Grid item sm={12} md={8}>
                        <CollectionBanner>
                            <Grid container style={{padding: '12px'}}>
                                <Grid item xs={3} sm={3} md={3} style={{padding: '4px', paddingTop: '12px'}}>
                                    {
                                        isLoading ? (
                                            <CircularProgress />
                                        ) : (
                                            <img width={"100%"} src={collection ? collection.imageURI : "/Ordia_logo.png"} style={{borderRadius: '50%'}}/>
                                        )
                                    }
                                </Grid>

                                <Grid item xs={9} sm={9} md={9} style={{padding: '12px', paddingLeft: '24px'}}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} style={{textAlign: 'left'}}>
                                            <span style={{
                                                fontSize: '1.5rem',
                                                textAlign: 'left'
                                            }}>{collection ? collection.name : "n/a"}</span>

                                            <div className="icons" style={{textAlign: 'left'}}>
                                                <a href={collection ? collection.twitterLink : ""} target="_blank"
                                                   rel="noopener noreferrer">
                                                    <FontAwesomeIcon icon={faXTwitter} size="sm"/>
                                                </a>
                                                <a href={collection ? collection.discordLink : ""} target="_blank"
                                                   rel="noopener noreferrer">
                                                    <FontAwesomeIcon icon={faDiscord} size="sm"/>
                                                </a>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12}>
                                            <span>
                                                {collection ? collection.description : "n/a"}
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <br/>
                            <Grid container>
                                <Grid item xs={12} md={12}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={4} sm={4} md={4}>
                                            <CollectionStatCard>
                                                <div style={{fontSize: '.75rem'}}>Collected</div>
                                                <div style={{fontSize: '1.5rem'}}>
                                                    {inscriptions ? inscriptions.length : 'n/a'}
                                                </div>
                                            </CollectionStatCard>
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4}>
                                            <CollectionStatCard>
                                                <div style={{fontSize: '.75rem'}}>Floor&nbsp;
                                                    <span style={{fontSize: '.4rem'}}>(BTC)</span>
                                                </div>
                                                <div style={{fontSize: '2rem'}}>
                                                    <div style={{fontSize: '1.5rem'}}>
                                                        {collectionDetails ? (parseFloat(collectionDetails.floorPrice) / 100000000).toFixed(4) : 'n/a'}
                                                    </div>
                                                </div>

                                            </CollectionStatCard>
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4}>
                                            <CollectionStatCard>
                                                <div style={{fontSize: '.75rem'}}>Owners</div>
                                                <div style={{fontSize: '1.5rem'}}>
                                                    {collectionDetails ? collectionDetails.owners : 'n/a'}
                                                </div>
                                            </CollectionStatCard>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                isOwner && (
                                    <Container disableGutters={true} style={{paddingTop: '12px'}}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <h2>Owner Tools</h2>
                                        </Grid>
                                        <Grid item xs={12} style={{padding: '12px'}}>
                                            <ColoredButton onClick={() => hideSetProposal()}>
                                                Create Proposal
                                            </ColoredButton>
                                        </Grid>
                                        {
                                            // TODO: Verify is owner on top level inscription
                                            showProposalForm ? (
                                                <CreateProposal inscription_id={collection?.name} address={address.ordinals}
                                                                fetchProposals={fetchProposals}
                                                                hideCreateProposal={hideSetProposal}/>
                                            ) : (
                                                <div></div>
                                            )
                                        }
                                    </Container>
                                )
                            }

                            <br/>

                            <Grid container spacing={2} style={{paddingTop: '24px'}}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <h2>Collected</h2>
                                </Grid>
                                {
                                    inscriptions && inscriptions.length > 0 ? inscriptions.map((inscription: MagicEdenTokens, index) => (
                                            <Grid item xs={4} sm={4} md={3} key={inscription.id} onClick={() => routeChange(inscription.id)} style={{zIndex: 3}}>
                                                <InscriptionContent inscriptionId={inscription.id} width={"auto"} height={"100%"} type={inscription.contentType} imageURL={inscription.contentPreviewURI}/>
                                                <div style={{width: '100%', textAlign: 'center'}} >
                                                    <span style={{fontSize: '12px', fontWeight: 'bold', fontFamily: 'FWD'}}>{inscription.displayName}</span>
                                                        <div>
                                                            <Link to={`/inscription/${inscription.id}`}>
                                                                <PreviewIcon style={{color: '#87CEEB'}}/>
                                                            </Link>
                                                        </div>
                                                </div>
                                            </Grid>
                                        )
                                    ) : (
                                        <Grid item xs={4} sm={3} md={3}>
                                            <div style={{width: 'auto', textAlign: 'center', verticalAlign: 'middle', border: '1px solid white', padding: '1rem'}}>
                                                None Collected
                                                <div>
                                                    <Link to={`https://magiceden.io/ordinals/marketplace/${collection?.symbol}`} target="_blank" style={{color: 'white'}}>
                                                        <span style={{
                                                            fontSize: '10px',
                                                            fontFamily: 'FWD',
                                                            textDecoration: 'underline'
                                                        }}>Marketplace</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </Grid>
                                    )
                                }
                            </Grid>
                            <br/>
                        </CollectionBanner>

                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={12}>
                                <CollectionBanner>
                                    <div style={{width: '100%', textAlign: 'center', padding: '1rem'}}>
                                    <span style={{
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        fontFamily: 'FWD'
                                    }}>Glyphs</span>
                                        <div style={{paddingTop: '12px', color: 'grey'}}>
                                            coming soon...
                                        </div>
                                    </div>
                                </CollectionBanner>
                            </Grid>


                            <Grid item xs={12} md={12}>
                                <CollectionBanner>
                                    <div style={{width: '100%',paddingTop: '12px', textAlign: 'center'}}>
                                        <span style={{
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            fontFamily: 'FWD'
                                        }}>
                                            <CheckClaims collection_id={collection?.symbol}/>
                                        </span>
                                    </div>
                                </CollectionBanner>
                            </Grid>


                            <Grid item xs={12} md={12}>
                                <CollectionBanner>
                                    <div style={{width: '100%', textAlign: 'center', padding: '1rem'}}>
                                        <div style={{paddingTop: '12px', fontSize: '14px', color: '#FFFFFF'}}>More modules coming soon...</div>
                                    </div>
                                </CollectionBanner>
                            </Grid>

                        </Grid>
                    </Grid>

                    {
                        showError && (
                            <Alert severity="error" style={{fontFamily: 'FWD'}} onClose={() => setShowError(false)}>
                                Error: {errorMsg}
                            </Alert>
                        )
                    }
                </Grid>
            </Container>
        </div>
    );
}

export default OrdCollection;
