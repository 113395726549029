import * as React from 'react';
import Grid from "@mui/material/Grid";
import {Collection} from "../types/Collection";

type collectionDetailProps = {
    collection: Collection | undefined;
}

const detailNameStyle = {fontSize: '1em', padding: '6px 12px', textAlign: 'left', fontWeight: 'bold'};
const detailValueStyle = {padding: '6px 12px', fontSize: '1em', textAlign: 'right', overflow: 'hidden'}

const CollectionDetail = (props: collectionDetailProps) => {
    const truncateString = (str: string | undefined) => {
        if (str ===  undefined){
            return str;
        }
        if (str.length > 10) {
            return str.slice(0, 5) + '...' + str.slice(str.length - 3, str.length);
        }
        return str;
    }

    return (
        <Grid item xs={12} md={12} style={{fontFamily: 'FWD'}}>
            <Grid container>
                <Grid item xs={12} md={6} sx={detailNameStyle}>
                    <div>Inscription ID</div>
                </Grid>
                <Grid item xs={12} md={6} sx={detailValueStyle}>
                    <div>{truncateString(props.collection?.id.toString())}</div>
                </Grid>

                <Grid item xs={12} md={6} sx={detailNameStyle}>
                    <div>Inscription Number</div>
                </Grid>
                <Grid item xs={12} md={6} sx={detailValueStyle}>
                    <div>{truncateString(props.collection?.number.toString())}</div>
                </Grid>

                <Grid item xs={12} md={6} sx={detailNameStyle}>
                    <div>Owner</div>
                </Grid>
                <Grid item xs={12} md={6} sx={detailValueStyle}>
                    <div>{truncateString(props.collection?.genesis_address)}</div>
                </Grid>

                <Grid item xs={12} md={6} sx={detailNameStyle}>
                    <div>Content Type</div>
                </Grid>
                <Grid item xs={12} md={6} sx={detailValueStyle}>
                    <div>{props.collection?.content_type}</div>
                </Grid>

                <Grid item xs={12} md={6} sx={detailNameStyle}>
                    <div>Genesis Transaction</div>
                </Grid>
                <Grid item xs={12} md={6} sx={detailValueStyle}>
                    <div>{truncateString(props.collection?.genesis_tx_id)}</div>
                </Grid>


                <Grid item xs={12} md={6} sx={detailNameStyle}>
                    <div>Sat Rarity</div>
                </Grid>
                <Grid item xs={12} md={6} sx={detailValueStyle}>
                    <div>{props.collection?.sat_rarity}</div>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CollectionDetail;