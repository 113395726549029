import React from 'react';
import './VerifyWallet.css';
import {ButtonProps} from "@mui/material/Button";  // Ensure this file exists for fade effects
import { Container, Box, Typography, styled } from '@mui/material';
import NavBar from "./Navbar";
import CheckIcon from '@mui/icons-material/Check';

const CollectionBanner = styled(Container)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText('#000124'),
    backgroundColor: '#000000',
    width: '100%',
    height: 'auto',
    fontSize: '1rem',
    border: '1px solid #2c2c2c',
    borderRadius: '.75rem',
    textAlign: 'left',
    fontFamily: 'FWD',
    cursor: 'pointer',
    boxShadow: '0 0 3px rgba(0, 0, 0, 0.0)',
    transition: 'border 0.3s',
    padding: '24px',
    minHeight: '25vw',
    minWidth: '25vw',

    '&:hover': {
        border: '1px solid #00BFFF',
        boxShadow: '0 0 4px #00BFFF',
    },
}));

const VerifyWallet: React.FC = () => {
    return (
        <Container>
            <NavBar isHub={true}/>

            <Container
                maxWidth="sm"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100vh', // Ensures full height for centering
                }}
            >
                <CollectionBanner>
                    Spacemen Loading...

                    <Container>
                        <div style={{textAlign: 'center', padding: '24px 24px', paddingTop: '48px'}}>
                            Verification Successful
                        </div>

                        <div style={{textAlign: 'center', color: '#4BFF72'}}>
                            <CheckIcon/>
                        </div>
                    </Container>

                </CollectionBanner>

            </Container>


        </Container>
    );
};

export default VerifyWallet;
